.LoginForm {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 20px;
  font-size: 1.2rem;
  text-align: center;
  max-width: 768px;
}

.LoginForm label {
  width: 100%;
}

.LoginForm input {
  height: 3rem;
  width: 100%;
  background-color: #ffebc1;
  border: none;
  border-radius: 55px;
  font-size: 1.2rem;
  padding: 0 20px;
  margin-top: 20px;
}

.LoginForm #submit:active {
  background-color: #d7a86e;
}

.LoginForm .registerLink {
  margin-top: 20px;
}

.LoginForm .registerLink p {
  margin-bottom: 10px;
}
