.UpdateCoffeeForm {
  background-color: #ffebc1;
  padding: 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.UpdateCoffeeForm input,
select,
option {
  height: 3rem;
  width: 100%;
  background-color: #d7a86e;
  border: none;
  border-radius: 55px;
  font-size: 1.2rem;
  padding: 0 20px 0 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffebc1;
}

.UpdateCoffeeForm #submit {
  background-color: #a64b2a;
}

.UpdateCoffeeForm #submit:active {
  background-color: #a64b2a;
}
