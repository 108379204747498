.Stars {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  width: 20rem;
  margin: 0 auto;
}

.Stars img {
  width: 100%;
}
