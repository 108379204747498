.EditStars {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 20rem;
  margin: 20px auto;
}

.EditStars img {
  width: 100%;
  fill: blue;
}
