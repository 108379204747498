main {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 6rem auto;
  grid-template-rows: 6rem auto;
  height: 100%;
  max-width: 768px;
  margin: 0 auto;
  background-color: #fff;
}

.mainContainer {
  overflow-y: scroll;
  padding-top: 20px;
}
