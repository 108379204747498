.ThermosStatusBrew {
  background-color: #ffebc1;
  padding: 20px;
  font-size: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
}

.ThermosStatusBrew .info {
  background-color: #d7a86e;
  height: 6rem;
  border-radius: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.ThermosStatusBrew p {
  color: #ffebc1;
}

.ThermosStatusBrew #tiny {
  margin-top: 10px;
  font-size: 1rem;
}

.ThermosStatusBrew button {
  background-color: #a64b2a;
  height: 3rem;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  color: #ffebc1;
  font-size: 1.2rem;
}
