.Coffeemug {
  position: relative;
}

.Coffeemug img {
  width: 20rem;
  display: block;
  position: relative;
}

.Coffeemug div {
  position: absolute;
  bottom: 1rem;
  background-color: #d7a86e;
  width: 16rem;
  left: 2rem;
  border-radius: 0 0 55px 55px;
  -webkit-transition: 3s ease-in-out;
  -o-transition: 3s ease-in-out;
  transition: 3s ease-in-out;
  bottom: 30px;
}
