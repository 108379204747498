.NavbarVisible {
  background-color: #ffebc1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-auto-rows: auto -webkit-max-content;
  grid-auto-rows: auto max-content;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  padding-bottom: 20px;
  z-index: 2;
  max-width: 768px;
}

.NavbarVisible ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
  text-align: center;
  gap: 20px;
}

.NavbarVisible ul * {
  text-decoration: none;
}

.Navbar {
  display: none;
}

.NavbarVisible .user {
  font-size: 1.2rem;
}

.NavbarVisible .user button {
  width: 100%;
  height: 3rem;
  background-color: #a64b2a;
  color: #ffebc1;
  border: none;
  border-radius: 55px;
  font-size: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}
