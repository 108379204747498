@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif;
  color: #8e3200;
}

html {
  height: 100%;
}

body {
  overflow: hidden;
  height: 100%;
  background-color: #fff3db;
}

h1 {
  font-size: 1.8rem;
  font-weight: 400;
}

h2 {
  font-size: 1.7rem;
  font-weight: 400;
}

h3 {
  font-size: 1.2rem;
  font-weight: 400;
}

#root {
  height: 100%;
}
