.EditStarsPopup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 6rem;
  width: 20rem;
  height: 15rem;
  background-color: #ffebc1;
  border-radius: 55px;
  margin: auto;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.EditStarsPopup button {
  width: 100%;
  height: 3rem;
  background-color: #a64b2a;
  color: #ffebc1;
  font-size: 1.2rem;
  border: none;
  border-radius: 55px;
  cursor: pointer;
}

.EditStarsPopup h2 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
