.ManageUsersComponent {
  font-size: 1.2rem;
}

.ManageUsersComponent .table {
  background-color: lightblue;
  width: 100%;
}

.ManageUsersComponent .card {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px auto 20px auto;
  grid-template-columns: 1fr auto auto;
  grid-gap: 20px;
  margin-bottom: 20px;
  height: 3rem;
}

.ManageUsersComponent .card .username {
  background-color: #d7a86e;
  border-radius: 55px;
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.ManageUsersComponent .card p {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.ManageUsersComponent button {
  width: 5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: #ffebc1;
  background-color: #a64b2a;
  border: none;
  border-radius: 55px;
}
