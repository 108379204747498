.MyRatings {
  padding: 0 20px 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: scroll;
}

.MyRatings .MyRatingsContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.MyRatings #intro {
  width: 15rem;
  font-size: 1.2rem;
  margin-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 550px) {
  .MyRatings .MyRatingsContainer {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
