.Hamburger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 2rem;
  -ms-grid-column-align: end;
  justify-self: end;
  cursor: pointer;
  z-index: 3;
  -webkit-tap-highlight-color: transparent;
}

.Hamburger .HamburgerLine {
  height: 5px;
  width: 3rem;
  background-color: #a64b2a;
  border-radius: 55px;
}
