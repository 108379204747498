.YourStars {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: 10rem;
  margin: 0 auto;
}

.YourStars img {
  width: 100%;
}
