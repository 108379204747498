.BrewHistory {
  padding: 0 20px 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: scroll;
}

.BrewHistory .BrewHistoryContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.BrewHistory .container #intro {
  width: 15rem;
  font-size: 1.2rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

.BrewHistory .container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.BrewHistory .container input {
  height: 3rem;
  font-size: 1.2rem;
  border-radius: 55px;
  background-color: #a64b2a;
  border: none;
  color: #ffebc1;
  cursor: pointer;
}

.BrewHistory .container label {
  text-align: center;
  font-size: 1.2rem;
}

.BrewHistory .container h2 {
  text-align: center;
  margin: 20px 0;
}

@media only screen and (max-width: 550px) {
  .BrewHistory .container .BrewHistoryContainer {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
