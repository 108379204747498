.Header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20% 20px auto 20px 20%;
  grid-template-columns: 20% auto 20%;
  grid-gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 20px;
  height: 6rem;
}

.Header img {
  -ms-grid-column-align: start;
  justify-self: start;
  height: calc(6rem - 40px);
}

.Header button {
  -ms-grid-column: 2;
  grid-column-start: 2;
  height: 3rem;
  background-color: #a64b2a;
  color: #ffebc1;
  border: none;
  border-radius: 55px;
  font-size: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}
