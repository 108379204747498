.BrewHistoryCard {
  background-color: #ffebc1;
  font-size: 1.2rem;
  border-radius: 55px;
  width: 15rem;
  height: 15rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  position: relative;
  margin-top: 20px;
  padding: 20px;
}

.BrewHistoryCard .text button {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: #8e3200;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BrewHistoryCard .check {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  background-color: #8e3200;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BrewHistoryCard .text button img {
  width: 50%;
}

.BrewHistoryCard .text .check img {
  width: 50%;
}

.BrewHistoryCard .text p {
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.BrewHistoryCard .text {
  margin-top: 10px;
  width: 100%;
}

.BrewHistoryCard .text p:nth-child(5) {
  margin-bottom: 20px;
}
