.AddBeansForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-size: 1.2rem;
}

.AddBeansForm input {
  height: 3rem;
  width: 100%;
  background-color: #d7a86e;
  border: none;
  border-radius: 55px;
  font-size: 1.2rem;
  padding: 0 20px 0 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffebc1;
}
