.ThermosStatus {
  padding: 20px;
  font-size: 1.2rem;
}

.ThermosStatus .ThermosTitle {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
}

.ThermosStatus .Left {
  margin: 20px 0 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ThermosStatus .Right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
}

.ThermosStatus .CoffeeStat {
  background-color: #d7a86e;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 55px;
}

.ThermosStatus .CoffeeStat p {
  color: #ffebc1;
}
