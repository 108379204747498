.EditUserForm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 6rem;
  width: calc(100% - 40px);
  max-width: 700px;
  max-height: 700px;
  background-color: #ffebc1;
  border-radius: 55px;
  margin: auto;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.EditUserForm form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.EditUserForm .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.EditUserForm label {
  font-size: 1.2rem;
}

.EditUserForm input {
  height: 3rem;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 55px;
  padding-left: 20px;
  font-size: 1.2rem;
  border: none;
  background-color: #d7a86e;
  color: #ffebc1;
}

.EditUserForm select {
  margin-top: 0;
}

.EditUserForm button {
  width: 100%;
  height: 3rem;
  background-color: #a64b2a;
  color: #ffebc1;
  font-size: 1.2rem;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  margin-top: 20px;
}

.EditUserForm #close {
  position: absolute;
  top: 0;
  right: 20px;
  width: 3rem;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.EditUserForm #close img {
  width: 50%;
}
