.CoffeeBeanList {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

.CoffeeBeanList .BeanCard {
  background-color: #d7a86e;
  font-size: 1.2rem;
  border-radius: 55px;
  width: 15rem;
  height: 15rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin-top: 20px;
  padding: 20px;
  gap: 10px;
}

.CoffeeBeanList .BeanCard p,
h2 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
